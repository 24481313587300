
/**
 * Module dependencies.
 */

import $ from 'jquery';
import 'bootstrap-sass';

/**
 * Tabs.
 */

 $(document).ready(() => {
   const element = $('a[href="' + location.hash + '"]');
   const mediaItemElement = $('[menu-media-item]');

   if(location.hash !== '') {
     element.tab('show');
     mediaItemElement.removeClass('active');
     element.addClass('active');
   }
 });
