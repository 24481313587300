
/**
 * Module dependencies.
 */

import $ from 'jquery';
import 'magnific-popup';

/**
 * Global variables.
 */

const elementLightbox = $('[lightbox-gallery]');
const elementPopup = $('#medias-list');
const elementVideo = $('[lightbox-video]');

/**
 * `Export` lightbox.
 */

export function lightbox() {
  if(elementLightbox.length === 0) {
    return;
  }

  elementLightbox.magnificPopup({
    delegate: 'a',
    gallery:{
      enabled: true
    },
    mainClass: 'mfp-fade-in',
    type: 'image'
  });
}

/**
 * `Export` popup.
 */

export function popup() {
  if(elementPopup.length === 0) {
    return;
  }

  elementPopup.magnificPopup({
    delegate: 'a',
    mainClass: 'lightbox-popup mfp-move-from-top',
    midClick: true,
    removalDelay: 500,
    type: 'ajax'
  });
}

/**
 * `Export` video.
 */

export function video() {
  if(elementVideo.length === 0) {
    return;
  }

  elementVideo.magnificPopup({
    iframe: {
      markup: `
        <div class="mfp-iframe-scaler">'
          <div class="mfp-close"></div>
          <iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>
        </div>`,
      patterns: {
        youtube: {
          id: 'v=',
          index: 'youtube.com/',
          src: '//www.youtube.com/embed/%id%?autoplay=1'
        },
        vimeo: {
          id: '/',
          index: 'vimeo.com/',
          src: '//player.vimeo.com/video/%id%?autoplay=1'
        }
      }
    },
    srcAction: 'iframe_src',
    type: 'iframe'
  });
}
