
/**
 * Export ``breakpoints`.
 */

export const breakpoints = {
  lg: 1200,
  md: 992,
  sm: 768,
  xs: 480
}

/**
* Export `galleryMock`.
*/

export const galleryMock = [{
  src: './images/image-6.jpg'
}, {
  src: './images/image-7.jpg'
}, {
  src: './images/image-8.jpg'
}, {
  src: './images/image-9.jpg'
}, {
  src: './images/image-10.jpg',
}];

/**
* Export `mapMarkers`.
*/

export const mapMarkers = [
  [
    'Convento de Balsamão',
    41.474681,
    -6.855663,
    4,
    'convento-de-balsamao'
  ], [
    'Olmos',
    41.484727,
    -6.855472,
    5,
    'olmos'
  ], [
    'Morais',
    41.481207,
    -6.844799,
    3,
    'morais'
  ], [
    'Lombo',
    41.468662,
    -6.843624,
    2,
    'lombo'
  ], [
    'Salselas',
    41.511900,
    -6.866846,
    1,
    'salselas'
  ]
];

/**
* Export `mapSkin`.
*/

export const mapSkin = [{
  "featureType": "administrative",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#6195a0"
  }]
}, {
  "featureType": "landscape",
  "elementType": "all",
  "stylers": [{
    "color": "#f2f2f2"
  }]
}, {
  "featureType": "landscape",
  "elementType": "geometry.fill",
  "stylers": [{
    "color": "#ffffff"
  }]
}, {
  "featureType": "poi",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "poi.park",
  "elementType": "geometry.fill",
  "stylers": [{
    "color": "#e6f3d6"
  }, {
    "visibility": "on"
  }]
}, {
  "featureType": "road",
  "elementType": "all",
  "stylers": [{
    "saturation": -100
  }, {
    "lightness": 45
  }, {
    "visibility": "simplified"
  }]
}, {
  "featureType": "road.highway",
  "elementType": "all",
  "stylers": [{
    "visibility": "simplified"
  }]
}, {
  "featureType": "road.highway",
  "elementType": "geometry.fill",
  "stylers": [{
    "color": "#f4d2c5"
  }, {
    "visibility": "simplified"
  }]
}, {
  "featureType": "road.highway",
  "elementType": "labels.text",
  "stylers": [{
    "color": "#4e4e4e"
  }]
}, {
  "featureType": "road.arterial",
  "elementType": "geometry.fill",
  "stylers": [{
    "color": "#f4f4f4"
  }]
}, {
  "featureType": "road.arterial",
  "elementType": "labels.text.fill",
  "stylers": [{
    "color": "#787878"
  }]
}, {
  "featureType": "road.arterial",
  "elementType": "labels.icon",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "transit",
  "elementType": "all",
  "stylers": [{
    "visibility": "off"
  }]
}, {
  "featureType": "water",
  "elementType": "all",
  "stylers": [{
    "color": "#eaf6f8"
  }, {
    "visibility": "on"
  }]
}, {
  "featureType": "water",
  "elementType": "geometry.fill",
  "stylers": [{
    "color": "#eaf6f8"
  }]
}];
