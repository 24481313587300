
/**
 * Module dependencies.
 */

import _ from 'lodash';
import { breakpoints } from './configuration';

export const mediaQueries = _.reduce(breakpoints, (result, value, key) => {
  const maxMediaQuery = window.matchMedia(`(max-width: ${value - 1}px)`).matches;
  const minMediaQuery = window.matchMedia(`(min-width: ${value}px)`).matches;

  return {
    max: {
      ...result.max,
      [key]: maxMediaQuery
    },
    min: {
      ...result.min,
      [key]: minMediaQuery
    }
  };
}, {});
