
/**
 * Module dependencies.
 */

import { Controller, Scene } from 'scrollmagic';

/**
 * Global variables.
 */

const triggerElement = document.querySelectorAll('.sticky-trigger');

/**
 * `Export` sticky.
 */

export function sticky() {
  if (triggerElement.length === 0) {
    return;
  }

  const controller = new Controller();

  new Scene({ triggerElement })
    .setPin('[sticky]')
    .addTo(controller);
}
