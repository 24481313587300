
/**
 * Module dependencies.
 */

import 'videojs-youtube';
import videojs from 'video.js';

/**
 * Global variables.
 */

const documentElement = document;
const videoElement = documentElement.getElementsByTagName('video')[0];

/**
 * Export videojs.
 */

export function playVideo() {
  if(!videoElement) {
    return;
  }

  const target = videoElement.getAttribute('data-target');
  const url = videoElement.getAttribute('data-video-url');
  const options = {
    controls: true,
    sources: [{
      src: url,
      type: 'video/youtube'
    }],
    techOrder: ['youtube', 'html5']
  }

  videojs(target, options);
}
