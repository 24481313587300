
/**
 * Module dependencies.
 */

import { mapMarkers, mapSkin } from './configuration';
import { mediaQueries } from './media-queries';
import $ from 'jquery';

/**
 * Map.
 */

$(document).ready(() => {
  const mapElement = document.getElementById('map');
  const mapMarker = document.getElementById('marker');
  const zoomIn = document.getElementById('zoom-in-control');
  const zoomOut = document.getElementById('zoom-out-control');

  if(!mapElement) {
    return;
  }

  // Set map options.
  const mapOptions = {
    draggable: mediaQueries.min.xs,
    mapTypeControl: false,
    panControl: false,
    scaleControl: false,
    scrollwheel: false,
    streetViewControl: false,
    zoom: 13,
    zoomControl: false
  }

  // Set map skin.
  const styledMapOptions = { name: 'SKIN' };
  const skinMapType = new google.maps.StyledMapType(mapSkin, styledMapOptions);

  const map = new google.maps.Map(mapElement, mapOptions);

  map.mapTypes.set('SKIN', skinMapType);
  map.setMapTypeId('SKIN');

  // Draw Overlay.
  const overlay = new google.maps.OverlayView();
  overlay.draw = function() {};

  // Map default configurations.
  function mapDefaultConfiguration() {
    const latitude = $(mapElement).data('lat');
    const longitude = $(mapElement).data('lng');
    const iconMarker = $(mapElement).data('icon');
    const centerPosition = new google.maps.LatLng(latitude, longitude);

    // Set marker options.
    const markerOptions = {
      icon: iconMarker,
      position: centerPosition
    }

    const marker = new google.maps.Marker(markerOptions);

    map.setCenter(centerPosition);
    marker.setMap(map);
  }

  // Set marker projection.
  function setMarkerProjection(marker, title, slug) {
    const projection = overlay.getProjection();
    const pixel = projection.fromLatLngToContainerPixel(marker.getPosition());
    const positionX = pixel.x;
    const positionY = pixel.y;

    $(mapMarker).css({
      'left': positionX - 50,
      'top': positionY - 60
    });

    $(mapMarker).find('span').html(title);
    $(mapMarker).attr('href', `/pontos-de-interesse/ficha/${slug}`);
    $(mapMarker).fadeIn();
  }

  // Set markers to the map.
  function setMarkers(_map) {
    const image = {
      origin: new google.maps.Point(0, 0),
      size: new google.maps.Size(20, 20),
      url: '../images/marker-circular.svg'
    };

    poisArray.forEach(function(value) {
      const marker = new google.maps.Marker({
        icon: image,
        map: _map,
        position: { lat: Number(value.lat), lng: Number(value.lng) },
        title: value.title,
        zIndex: 1
      });

      google.maps.event.addListener(marker, 'mouseover', () => {
        setMarkerProjection(marker, value.title, value.slug);
      });
    });
  }

  // Map list configuration.
  function mapListConfiguration() {
    const centerPosition = new google.maps.LatLng(41.492480, -6.873698);

    overlay.setMap(map);

    map.setCenter(centerPosition);
    map.setZoom(12);
    setMarkers(map);
  }

  // Zoom-in control.
  function ZoomOutControl() {
    const currentZoomLevel = map.getZoom();

    if(currentZoomLevel > 0) {
      map.setZoom(currentZoomLevel - 1);
    }
  }

  // Zoom-out control.
  function ZoomInControl() {
    const currentZoomLevel = map.getZoom();

    if(currentZoomLevel < 21) {
      map.setZoom(currentZoomLevel + 1);
    }
  }

  // Intro overlay.
  function introOverlay() {
    const overlayElement = $('[intro-overlay]');

    if(window.location.hash) {
      overlayElement.addClass('hidden');
    }
  }

  if($(mapElement).hasClass('map-type-2')) {
    mapDefaultConfiguration();
  } else {
    introOverlay();
    mapListConfiguration();
  }

  // Add listner zoom control.
  google.maps.event.addDomListener(zoomIn, 'click', ZoomInControl);
  google.maps.event.addDomListener(zoomOut, 'click', ZoomOutControl);
});
