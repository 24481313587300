
/**
 * Module dependencies.
 */

import './components/map';
import './components/tabs';
import './components/toggle-class';
import * as media from './components/lightbox';
import * as parallax from './components/parallax';
import * as scrollmagic from './components/animations';
import * as video from './components/video';
import $ from 'jquery';

/**
 * Init.
 */

$(document).ready(() => {
  media.lightbox();
  media.popup();
  media.video();
  parallax.imageParallax();
  parallax.mouseParallax();
  parallax.thumbsParallax();
  scrollmagic.sticky();
  video.playVideo();
});
