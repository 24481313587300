
/**
 * Module dependencies.
 */

import 'parallax-imagescroll';
import 'parallax';
import { forEach } from 'lodash';
import $ from 'jquery';

/**
 * Global variables.
 */

const documentElement = document;
const bodyNode = documentElement.getElementsByTagName('body')[0];
const htmlElement = documentElement.getElementsByTagName('html')[0];
const parallaxElements = documentElement.querySelectorAll('.parallax-hover');
const numberOfImages = parallaxElements.length;
const supportsTouch = 'ontouchstart' in window || !!navigator.msMaxTouchPoints;

/**
 * Transform layer container.
 */

function transformLayerContainer(element, xRotate, yRotate) {
  let imageCssTransform = `rotateX(${xRotate}deg) rotateY(${yRotate}deg)`;

  if (element.firstChild.className.indexOf('over') !== -1) {
    imageCssTransform += ' scale3d(1.1, 1.1, 1.1)';
  }

  element.firstChild.style.transform = imageCssTransform;
}

/**
 * Process movement.
 */

function processMovement(event, element, layers) {
  const scrollTopElement = bodyNode.scrollTop || htmlElement.scrollTop;
  const scrollLeftElement = bodyNode.scrollLeft;
  const pageX = event.pageX;
  const pageY = event.pageY;
  const offsets = element.getBoundingClientRect();
  const wMultiple = 380 / element.clientWidth;
  const offsetX = (pageX - offsets.left - scrollLeftElement) / element.clientWidth;
  const offsetY = (pageY - offsets.top - scrollTopElement) / element.clientHeight;
  const dy = pageY - offsets.top - scrollTopElement - element.clientHeight / 2;
  const dx = pageX - offsets.left - scrollLeftElement - element.clientWidth / 2;
  const yRotate = (offsetX - dx) * (0.07 * wMultiple);
  const xRotate = (dy - offsetY) * (0.1 * wMultiple);

  // Container transform.
  transformLayerContainer(element, xRotate, yRotate);

  // Add parallax effect to each layer by updating `translateX` and `translateY` CSS transform property depending on mouse position.
  forEach(layers, (layer, index) => {
    const depth = layer.getAttribute('data-depth');

    layer.style.transform = `translateX(${offsetX / 2 * (index * -depth)}px) translateY(${offsetY / 2 * (index * -depth)}px)`;
  });
}

/**
 * Process enter.
 */

function processEnter(element) {
  element.firstChild.className += ' over';
}

/**
 * Process exit.
 */

function processExit(element, layers) {
  const container = element.firstChild;

  container.className = container.className.replace(' over', '');
  container.style.transform = '';

  forEach(layers, (layer) => {
    layer.style.transform = '';
  });
}

/**
 * Bind mouse events.
 */

function bindMouseEvents(element, layers) {
  if (supportsTouch) {
    window.preventScroll = false;
  }

  element.addEventListener('mousemove', (event) => {
    processMovement(event, element, layers);
  });

  element.addEventListener('mouseenter', () => {
    processEnter(element);
  });

  element.addEventListener('mouseleave', () => {
    processExit(element, layers);
  });
}

/**
 * Add layer
 */

function addLayer(numberOfLayerElements, layerElements, layersNode, layers) {
  for (let index = 0; index < numberOfLayerElements; index++) {
    const layer = documentElement.createElement('div');
    const layerElement = layerElements[index];
    const imageUrl = layerElement.getAttribute('data-img');

    layer.className = 'parallax-rendered-layer';
    layer.setAttribute('data-layer', index);
    layer.setAttribute('data-depth', layerElement.getAttribute('data-depth') || 50);

    if (imageUrl) {
      layer.style.backgroundImage = `url(${imageUrl})`;
    }

    layer.innerHTML = layerElement.innerHTML;

    layersNode.appendChild(layer);
    layers.push(layer);
  }
}

/**
 * Build Html.
 */

function buildHtml() {
  for (let key = 0; key < numberOfImages; key++) {
    const image = parallaxElements[key];
    const layerElements = image.querySelectorAll('.parallax-layer');
    const numberOfLayerElements = layerElements.length;

    if (numberOfLayerElements <= 0) {
      continue;
    }

    while (image.firstChild) {
      image.removeChild(image.firstChild);
    }

    const containerNode = documentElement.createElement('div');
    const dropShadowNode = documentElement.createElement('div');
    const layersNode = documentElement.createElement('div');

    const layers = [];

    containerNode.className = 'parallax-container';
    dropShadowNode.className = 'parallax-shadow';
    image.id = `parallax-item-${key}`;
    layersNode.className = 'parallax-layers';

    addLayer(numberOfLayerElements, layerElements, layersNode, layers);

    containerNode.appendChild(dropShadowNode);
    containerNode.appendChild(layersNode);
    image.appendChild(containerNode);

    const widthElement = image.clientWidth || image.offsetWidth || image.scrollWidth;

    image.style.transform = `perspective(${widthElement * 3}px)`;

    bindMouseEvents(image, layers);
  }
}

/**
 * Image parallax.
 */

export function imageParallax() {
  const imagescrollElement = $('[parallax-imagescroll]');

  imagescrollElement.imageScroll();
}

/**
 * Mouse parallax.
 */

export function mouseParallax() {
  $('[parallax-mousemove]').parallax();
}

/**
 * Thumbs parallax.
 */

export function thumbsParallax() {
  if (numberOfImages <= 0) {
    return;
  }

  buildHtml();
}
