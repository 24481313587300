
/**
 * Module dependencies.
 */

import $ from 'jquery';

/**
 * Toggle class.
 */

$(document).ready(() => {
  const sectionTarget = $('[data-toggle-class]');

  // Toggle class.
  function toggleClass() {
    const element = $(this);
    const className = element.data('toggle-class');
    const target = element.data('target');

    $(target).toggleClass(className);
  }

  sectionTarget.on('click', toggleClass);
});
